<template>
  <el-dialog
    :title="type === 'create' ? '新建' : '编辑'"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="cancel"
    width="600px"
  >
    <field label="类目名称：" :asterisk="true" labelWidth="110px" :validation="validation.name" >
      <el-input v-model="formData.name" placeholder="请输入规格名称"></el-input>
    </field>
    <field label="规格参数：" :asterisk="true" labelWidth="110px" :validation="validation.items" >
      <el-input v-model="formData.items" placeholder="请输入规格参数"></el-input>
    </field>
    <div class="footer">
      <el-button type="primary" @click="confirm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as v from '@/utils/validator'
import validation from '@/mixins/validation'
import * as R from 'ramda'
const init = {
  name: '',
  items: ''
}

export default {
  naem: 'editRole',
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    type: {
      type: String,
      default: 'create'
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      formData: R.clone(init)
    }
  },
  mixins: [validation({
    rules: {
      name: [v.required('请输入规格名称')],
      items: [v.required('请输入规格参数')]
    },
    field: 'formData'
  })],
  methods: {
    cancel () {
      this.$emit('onCancel')
      this.formData = R.clone(init)
    },
    async confirm () {
      await this.$validate()
      const postData = {
        name: this.formData.name,
        items: R.split(',', this.formData.items).filter(o => o !== '').map(o => ({ name: o }))
      }

      if (this.type === 'create') {
        this.$emit('onConfirmCreate', postData)
      } else {
        this.$emit('onConfirmEdit', postData)
      }
      this.formData = R.clone(init)
    }
  },
  watch: {
    data: {
      deep: true,
      handler: function (curVal, oldVal) {
        if (!R.isNil(curVal)) {
          this.formData = {
            name: curVal.name,
            items: R.pluck('name', curVal.items).join(',')
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #D9E0F0FF;
  padding-top: 30px;
  text-align: center;
}

</style>
