<template>
  <div class="container">

    <div class="content">
      <div class="operation">
        <el-button type="primary" icon="el-icon-plus" @click="handleCreate">新建</el-button>
        <el-popconfirm
          style="margin-left: 20px;"
          @confirm="batchDelete"
          title="确定删除规格吗？"
        >
          <el-button type="danger" plain :disabled="selected.length === 0" slot="reference">删除</el-button>
        </el-popconfirm>
      </div>
      <el-table
        ref="multipleTable"
        :data="specs"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="name"
          label="名称"
        >
        </el-table-column>
        <el-table-column
          prop="items"
          min-width="160"
          label="规格参数"
        >
          <template slot-scope="scope">
            <div>
              {{ getItem(scope.row.items) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="operation"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageOptions"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

    <EditContent
      :visible="visible"
      :type="type"
      :data="selectData"
      @onConfirmEdit="handleConfirmEdit"
      @onConfirmCreate="handleConfirmCreate"
      @onCancel="visible = false"
    />
  </div>
</template>

<script>
// import * as R from 'ramda'
import { pageOptions } from '@/utils/config'
import * as specService from '../../api/spec'
import * as R from 'ramda'
import EditContent from './edit'

export default {
  components: {
    EditContent
  },
  data () {
    return {
      specs: [],
      selected: [],
      total: 10,
      pageOptions: pageOptions,
      pageSize: 10,
      currentPage: 1,
      visible: false,
      type: 'create',
      selectData: null
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const res = await specService.list()
      this.specs = res.list
      this.total = res.totalElements
    },
    getItem (items) {
      return items.map(o => o.name).join(',')
    },
    handleCreate () {
      this.type = 'create'
      this.visible = true
    },
    handleTabChange (tab) {
      console.log('handleTabChange', tab)
    },
    handleSelectionChange (val) {
      this.selected = val
    },
    edit (data) {
      this.selectData = R.clone(data)
      this.type = 'edit'
      this.visible = true
    },
    async deleteOne (id) {
      try {
        await specService.deleteById(id)
        // this.fetchData()
      } catch (err) {
      }
    },
    async batchDelete () {
      try {
        const selected = this.selected
        await Promise.all(selected.map(o => this.deleteOne(o.id)))
        this.$notify.success({ title: '删除规格成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '删除规格发生错误' })
        // handle error
      }
    },
    async handleConfirmEdit (data) {
      try {
        await specService.update(this.selectData.id, data)
        this.visible = false
        this.$notify.success({ title: '编辑规格成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '编辑规格发生错误' })
      }
    },
    async handleConfirmCreate (data) {
      try {
        await specService.create(data)
        this.visible = false
        this.$notify.success({ title: '创建规格成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '创建规格失败' })
      }
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.fetchData(this.currentPage, val)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData(val, this.pageSize)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.content {
  .operation {
    padding: 20px;
  }
  margin-top: 10px;
  background: #FFFFFF;
  padding: 10px;
}
.pagination {
  margin-top: 30px;
  text-align: center;
}

</style>
